<template>
  <v-card height="150px" :to="`/management/restaurants/${restaurantId}/menu`">
    <v-card-title>Manage Menu Items</v-card-title>
    <v-card-text>
      <v-progress-circular indeterminate v-if="loading" />
      <div v-else><span class="text-h5">{{ itemCount }}</span> items in your menu.</div>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'MenuCard',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  computed: {
    collectionPath () {
      return `/restaurants/${this.restaurantId}/products`
    }
  },

  data () {
    return ({
      loading: false,
      itemCount: null
    })
  },

  methods: {
    getCount () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection(this.collectionPath).get()
          .then(querySnapshot => { resolve(querySnapshot.docs.length) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.itemCount = await this.getCount()
    } catch (e) {
      this.$data.itemCount = '??'
      console.error(e)
    }
    this.$data.loading = false
  }
}
</script>
