<template>
  <div>
    <v-overlay v-if="loading">
      <div class="d-flex align-stretch justify-center">
        <v-progress-circular indeterminate color="accent" />
      </div>
    </v-overlay>
    <h1 class="text-h4 mt-12 mb-3">Manage {{ restaurantData.name }}</h1>
    <v-container fluid class="mt-4">
      <v-row>
        <v-col cols="12">
          <PublicationSwitch :restaurant-id="this.restaurantId" />
        </v-col>
        <v-col cols="4" xs="12" sm="12" md="4" lg="3" xl="2">
          <BasicInfo :restaurant-id="this.restaurantId" />
        </v-col>
        <v-col cols="4" xs="12" sm="12" md="4" lg="3" xl="2">
          <Menu :restaurant-id="this.restaurantId" />
        </v-col>
        <v-col cols="4" xs="12" sm="12" md="4" lg="3" xl="2">
          <Gallery :restaurant-id="this.restaurantId" />
        </v-col>
        <v-col cols="4" xs="12" sm="12" md="4" lg="3" xl="2">
          <OpeningHour :restaurant-id="this.restaurantId" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <DeleteRestaurantCard :restaurant-id="this.restaurantId" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'

import PublicationSwitch from '@/components/RestaurantManagement/ManagementViewCards/PublicationSwitch.vue'
import BasicInfo from '@/components/RestaurantManagement/ManagementViewCards/BasicInfoCard'
import Menu from '@/components/RestaurantManagement/ManagementViewCards/MenuCard'
import Gallery from '@/components/RestaurantManagement/ManagementViewCards/GalleryCard'
import OpeningHour from '@/components/RestaurantManagement/ManagementViewCards/OpeningHourCard'
import DeleteRestaurantCard from '@/components/RestaurantManagement/ManagementViewCards/DeleteCard'

export default {
  name: 'RestaurantManager',

  components: { BasicInfo, Menu, Gallery, OpeningHour, PublicationSwitch, DeleteRestaurantCard },

  props: {
    restaurantId: {
      required: true,
      type: String
    }
  },

  data () {
    return ({
      loading: false,
      restaurantData: {}
    })
  },

  computed: {
    docPath () {
      return `/restaurants/${this.restaurantId}`
    }
  },

  methods: {
    getRestaurantData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.restaurantData = await this.getRestaurantData()
    } catch (e) {
      console.error(e)
      this.$store.commit('toast/setError', 'Failed getting restaurant data.')
    }
    this.$data.loading = false
  }
}
</script>
