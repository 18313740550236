<template>
  <v-card>
    <v-card-text>
      <h3 class="text-h5">Publish Restaurant</h3>
      <v-list-item two-line>
        <v-list-item-action>
          <v-switch :loading="loading" :value="published" @click.prevent="toggle" color="primary" hide-details="auto" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ published ? 'Published' : 'Not Published' }}</v-list-item-title>
          <v-list-item-subtitle v-text="words" />
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'PublicationSwitch',

  props: {
    'restaurant-id': {
      required: true,
      type: String
    }
  },

  computed: {
    words () {
      var whenPublished = 'This restaurant is published. It is visible to AmborJo visitors.'
      var notPublished = 'This restaurant is not published. It won\'t appear in AmborJo, except in your restaurant management menus.'

      return this.$data.published ? whenPublished : notPublished
    },

    docPath () {
      return `/restaurants/${this.restaurantId}`
    }
  },

  data () {
    return ({
      loading: false,
      published: false
    })
  },

  methods: {
    setPublication (v) {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).update({ published: v })
          .then(() => { resolve(v) })
          .catch(e => { reject(e) })
      })
    },

    getCurrentStatus () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).get()
          .then(doc => {
            if (doc.exists) {
              var restaurantData = doc.data()
              resolve(restaurantData.published)
            } else {
              reject(new Error("Restaurant doesn't exist."))
            }
          })
          .catch(e => { reject(e) })
      })
    },

    async toggle () {
      this.$data.loading = true
      try {
        this.$data.published = await this.setPublication(!this.$data.published)
      } catch (e) {
        console.error(e)
        this.$emit('error', e)
      }
      this.$data.loading = false
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.published = await this.getCurrentStatus()
    } catch (e) {
      console.error(e)
      this.$emit('error', e)
    }
    this.$data.loading = false
  }
}
</script>
