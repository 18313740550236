<template>
  <v-dialog v-model="dialogOpen">
    <template #activator="slotProps">
      <slot name="activator" v-bind="slotProps">
        <v-btn color="danger" v-on="slotProps.on">Delete Restaurant</v-btn>
      </slot>
    </template>

    <v-card>
      <v-overlay absolute v-if="loading">
        <v-container class="fill-height">
          <v-row align="stretch" justify="center">
            <v-progress-circular indeterminate />
          </v-row>
        </v-container>
      </v-overlay>
      <v-form v-else @submit.prevent="submit">
        <v-card-title>Delete {{ restaurantName }}</v-card-title>
        <v-card-text>
          <p>
            You are about to delete {{ restaurantName }} from AmborJo. This includes photos, costumer reviews, user-submitted contents, among others.
            You cannot get those back after you delete the restaurant.
          </p>
          <p>
            If you want to continue, type the name of the restaurant into the box below with correct capitalization then click Delete Restaurant.
          </p>
          <v-text-field v-model="userInput" label="Restaurant Name" hint="Type the name of the restaurant to continue" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn color="danger" :disabled="userInput !== restaurantName" type="submit"><v-icon left>mdi-delete</v-icon>Delete Restaurant</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'RestaurantDeleteDialog',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  computed: {
    docPath () {
      return `/restaurants/${this.restaurantId}`
    }
  },

  data () {
    return ({
      loading: false,
      dialogOpen: false,

      restaurantName: null,
      userInput: ''
    })
  },

  methods: {
    getRestaurantDetails () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).get()
          .then(doc => {
            if (doc.exists) {
              resolve(doc.data())
            } else {
              reject(new Error('Restaurant doesn\'t exist'))
            }
          })
          .catch(e => { reject(e) })
      })
    },

    deleteRestaurant () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).delete()
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },

    cancel () {
      this.$data.userInput = ''
      this.$data.dialogOpen = false
    },

    async submit () {
      this.$data.loading = true
      try {
        await this.deleteRestaurant()
        this.$router.push('/management/restaurants')
      } catch (e) {
        console.error(e)
        this.$emit('error', e)
      }
      this.$data.loading = false
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      var restaurantData = await this.getRestaurantDetails()
      this.$data.restaurantName = restaurantData.name
    } catch (e) {
      console.error(e)
      this.$emit('error', e)
    }
    this.$data.loading = false
  }
}
</script>
