<template>
  <v-card height="150px" :to="`/management/restaurants/${restaurantId}/business-hours`">
    <v-card-title>Opening Hours</v-card-title>
    <v-card-text v-if="loading"><v-progress-circular indeterminate /></v-card-text>
    <v-card-text v-else>
        Open every
        <v-chip class="ml-1" :ripple="false" small v-for="day in daysOpen" v-text="day" :key="day"/>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'OpenHourEditorCard',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  computed: {
    url () {
      return `/management/restaurants/${this.restaurantId}`
    },
    docPath () {
      return `/restaurants/${this.restaurantId}/`
    }
  },

  data () {
    return ({
      loading: false,
      daysOpen: null
    })
  },

  methods: {
    getRestaurantData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      var restaurantData = await this.getRestaurantData()
      this.$data.daysOpen = restaurantData.businessHours.hours.filter(day => day.isOpen).map(day => day.name)
    } catch (e) {
      console.error(e)
      this.$emit('error', e)
    }
    this.$data.loading = false
  }
}
</script>
