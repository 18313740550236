<template>
  <v-card height="150" :to="`/management/restaurants/${restaurantId}/basic-info`">
    <v-card-title>Basic Info</v-card-title>
    <v-card-text>
      <p>Click to edit basic info</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BasicInfoCard',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  }
}
</script>
