<template>
  <v-card>
    <v-card-title>Delete Restaurant</v-card-title>
    <v-card-text>
      <p>To delete the restaurant, click the button below.</p>

      <DeleteRestaurant :restaurant-id="restaurantId">
        <template #activator="{ on }">
          <v-btn color="danger" v-on="on">Delete Restaurant</v-btn>
        </template>
      </DeleteRestaurant>
    </v-card-text>
  </v-card>
</template>

<script>
import DeleteRestaurant from '@/components/RestaurantManagement/DeleteRestaurant'

export default {
  name: 'DeleteRestaurantCard',

  components: {
    DeleteRestaurant
  },

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  }
}
</script>
